<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    bottom
    width="300"
    offset-y
    z-index="1"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        :placeholder="$_strings.order.FILTER"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section class="ml-4">
        <div v-for="item in listItems" :key="item.key">
          <span class="caption font-weight-bold">{{item.title}}</span>
          <v-select
            :items="items[item.items]"
            :placeholder="$_strings.invoice.CHOOSE_STATUS"
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="filters[item.vModel]"
            class="caption mb-4 mr-4"
            dense
            hide-details
            flat
          />
        </div>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'fiter-selection-info',
  data() {
    return {
      closeOnContentClick: false,
      filters: {
        type: this.$route.query.type || 'BASP,CANCELLATION,ACCIDENT',
        needConfirm: this.$route.query.needConfirm || '',
        isFreeText: this.$route.query.isFreeText || '',
        isActive: this.$route.query.isActive || '',
      },
      items: {
        listTypeItems: this.$_strings.selectioninfo.STATUS_TYPE,
        filterItems: [
          {
            text: 'Semua',
            value: '',
          },
          {
            text: 'Ya',
            value: 'qistrue',
          },
          {
            text: 'Tidak',
            value: 'qisfalse',
          },
        ],
      },
      listItems: [
        {
          key: 1,
          title: 'Tipe',
          items: 'listTypeItems',
          vModel: 'type',
        },
        {
          key: 2,
          title: 'Butuh Konfirmasi',
          items: 'filterItems',
          vModel: 'needConfirm',
        },
        {
          key: 3,
          title: 'Free Text',
          items: 'filterItems',
          vModel: 'isFreeText',
        },
        {
          key: 4,
          title: 'Aktif',
          items: 'filterItems',
          vModel: 'isActive',
        },
      ],
    };
  },
  methods: {
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setNewFilters', this.filters);
    },
  },
};
</script>
