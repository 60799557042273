<template>
  <v-data-table
    :loading="isLoading"
    :headers="displayedHeader"
    :items="selectionInfoList"
    class="elevation-1 table"
    item-key="id"
    :options.sync="pagination"
    :server-items-length="selectionInfoListTotalEntry"
    @click:row="rowClicked"
    :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}">
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.selectioninfo.label.PAGE}}
        <span v-if="selectionInfoList.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  skipEmptyStringObject,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'selection-info-list',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      selectionInfoListTotalEntry: 0,
      selectionInfoList: [],
      headersTable: [
        { text: this.$_strings.selectioninfo.headers.ITEM, value: 'item' },
        { text: this.$_strings.selectioninfo.headers.TYPE, value: 'typeValue' },
        { text: this.$_strings.selectioninfo.headers.NEED_CONFIRM, value: 'needConfirm' },
        { text: this.$_strings.selectioninfo.headers.FREE_TEXT, value: 'isFreeText' },
        { text: this.$_strings.selectioninfo.headers.ACTIVE, value: 'isActive' },
        { text: this.$_strings.selectioninfo.headers.CREATED_BY, value: 'createdBy' },
      ],
      pagination: this.defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchSelectionInfoList();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headersTable.map((i) => ({
        ...i,
        class: 'white--text primary',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    skipEmptyStringObject,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.selectionInfoList.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    rowClicked(item) {
      this.$emit('showDialogEdit', item);
    },
    async fetchSelectionInfoList() {
      this.toggleLoading(true);
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        ...this.filters,
      };
      if (this.filters.name) filters.name = `qLike(${this.filters.name})`;
      if (this.filters.type) filters.type = `qIn(${this.filters.type})`;
      if (!this.filters.type) filters.type = 'qIn(BASP,CANCELLATION,ACCIDENT)';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0].includes('item') ? 'name' : sortBy[0]},${isDesc}`;
      }
      const importType = this.$_strings.selectioninfo.typeList;
      try {
        const result = await this.$_services.masterData.fetchSelectionsInfos(this.skipEmptyStringObject(filters), importType);
        const data = result.data.contents.map((e) => {
          const tempData = {
            id: e.id,
            item: e.reason,
            createdBy: e.createdBy,
            isActive: e.isActive ? 'Ya' : 'Tidak',
            needConfirm: e.needConfirm ? 'Ya' : 'Tidak',
            isFreeText: e.isFreeText ? 'Ya' : 'Tidak',
            typeValue: this.$_strings.selectioninfo.getTypeLabel(e.type),
          };
          return tempData;
        });
        this.selectionInfoList = data;
        this.selectionInfoListTotalEntry = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>
