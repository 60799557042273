<template>
  <v-container fluid>
    <v-row
      class="mb-4"
      no-gutters
      align="center">
      <v-col cols="12" md="9">
        <v-row no-gutters>
          <v-col cols="auto">
            <DialogCreate
              ref="dialogCreate"
              @fetchSelectionInfoList="key_list += 1"
            />
          </v-col>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mr-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  elevation="1"
                  small
                  :loading="downloadingTemplate"
                >
                  Download Data
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in dataTypeOptions"
                  :key="index"
                  link
                  @click="handleDropdownClick(item.type)"
                >
                  <v-list-item-title v-text="item.label"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col>
            <MasterDataCsvUpload
              :importTypeProp="$_strings.importType.REASON"
              :uploadCsvProp="uploadCsv"
              :downloadTemplateCsvProp="downloadTemplateCsv"
              dataTypeProp="selection_info"
              @fetchList="key_list += 1"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <v-row
          dense
          justify="end"
        >
          <!-- Filter -->
          <v-col cols="auto" class="ma-0">
            <FilterList
              @fetchList="key_list += 1"
              @setNewFilters="setNewFilters"
            />
          </v-col>
          <!-- Search -->
          <v-col cols="12">
            <v-text-field
              dense
              hide-details
              outlined
              v-model="search"
              @keyup.enter="setSearch"
              class="display-2 caption"
              :placeholder="$_strings.selectioninfo.label.SEARCHING_PLACEHOLDER"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setSearch">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <List
      :key="key_list"
      :filters="filters"
      @showDialogEdit="showDialogEdit"
    />
  </v-container>
</template>

<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import FilterList from './FilterList.vue';
import List from './List.vue';

const MasterDataCsvUpload = () => import('../Common/MasterDataCsvUpload');
const DialogCreate = () => import('./DialogCreate.vue');

export default {
  name: 'selection-info',
  components: {
    MasterDataCsvUpload,
    DialogCreate,
    FilterList,
    List,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.name || '',
      dataTypeOptions: this.$_strings.selectioninfo.typeList,
      downloadingTemplate: false,
      filters: {
        name: this.$route.query.name || '',
        type: this.$route.query.type || 'BASP,CANCELLATION,ACCIDENT',
        needConfirm: this.$route.query.needConfirm || '',
        isFreeText: this.$route.query.isFreeText || '',
        isActive: this.$route.query.isActive || '',
      },
    };
  },
  methods: {
    skipEmptyStringObject,
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.key_list += 1;
    },
    setSearch() {
      this.filters.name = this.search;
      if (this.$route.query.search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            name: this.search,
            page: 1,
          },
        });
        this.key_list += 1;
      }
    },
    showDialogEdit(item) {
      const converToBoolean = (value) => (value.toLowerCase() === 'ya');
      this.$refs.dialogCreate.form = {
        ...item,
        type: this.$_strings.selectioninfo.typeList.find((x) => x.label.toLowerCase() === item.typeValue.toLowerCase()).type,
        reason: item.item,
        needConfirm: converToBoolean(item.needConfirm),
        isFreeText: converToBoolean(item.isFreeText),
        isActive: converToBoolean(item.isActive),
      };
      this.$refs.dialogCreate.dialog = true;
    },
    uploadCsv(formData) {
      return this.$_services.uploads.uploadCsvReason(formData);
    },
    downloadTemplateCsv(type) {
      return this.$_services.uploads.fetchCsvTemplateDataReason(type);
    },
    handleDropdownClick(command) {
      this.downloadDataReason(command);
    },
    async downloadDataReason(command) {
      this.downloadingTemplate = true;
      try {
        const res = await this.$_services.masterData.selectionInfoDownload({
          name: this.search,
          type: command,
          needConfirm: this.filters.needConfirm,
          isFreeText: this.filters.isFreeText,
          isActive: this.filters.isActive,
        });
        const blob = new Blob([res.data], { type: 'application/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Data_Piihan_Alasan.csv';
        link.click();
      } finally {
        this.downloadingTemplate = false;
      }
    },
  },
};
</script>
